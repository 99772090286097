import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface outputType { time: string; minutes: number; cell_data: object; }
interface bookingsData { booking_no: string; member_no: string; court: string; name: string; }
interface memberType { memberNumber: string; memberName: string; }

function App() {
  const [output, setOutput] = useState<outputType[]>([]);
  const [members, setMembers] = useState<memberType[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    const day = startDate;
    const yy = day.getFullYear();
    const mm = day.getMonth() + 1;
    const dd = day.getDate();
    const dayDate = [yy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join(' ');

    var formdata = new FormData();
    formdata.append("function", "booking_sheet");
    formdata.append("cal_view", "0");
    formdata.append("source", "1");
    formdata.append("club_bookings", "1");
    formdata.append("sport_court", "A");
    formdata.append("date", dayDate);
    formdata.append("club_id", "6400004");

    fetch('https://internetbookings.net.nz/smii/php/ajax.php', {
      method: 'POST',
      body: formdata,
    })
      .then(response => response.json())
      .then(async result => {
        const data = result.bookings_data;
        const rows = data.rows;
        console.log(rows);
        setOutput(rows);

        const bookingDataEntry = data.bookings as {
          1: bookingsData[],
          2: bookingsData[],
          3: bookingsData[],
          4: bookingsData[],
          5: bookingsData[],
          6: bookingsData[],
          7: bookingsData[],
          8: bookingsData[],
          9: bookingsData[],
          10: bookingsData[],
          11: bookingsData[],
          num: number,
        };
        const memberData = [
          ...bookingDataEntry[1],
          ...bookingDataEntry[2],
          ...bookingDataEntry[3],
          ...bookingDataEntry[4],
          ...bookingDataEntry[5],
          ...bookingDataEntry[6],
          ...bookingDataEntry[7],
          ...bookingDataEntry[8],
          ...bookingDataEntry[9],
          ...bookingDataEntry[10],
          ...bookingDataEntry[11],
        ];
        const memberList = Array.from(new Set(memberData.map((item: bookingsData) => {
          return {
            memberNumber: item.member_no,
            memberName: item.name
          }
        })));
        console.log(memberList);
        setMembers(memberList)

        // const output = [];
        // rows.forEach((row: { time: string; minutes: number; cell_data: object }) => {

        //   Object.entries(row.cell_data).forEach(([key, value]) => {
        //     if (value.title.length === 0) {
        //       output.push({ time: row.time, minutes: row.minutes, court: key });
        //     }
        //   })
        // });
      })
      .catch(error => console.log('error', error));
  }, [startDate])

  return (
    <>
      <div id="main-wrapper">
        <div className="nav-header">
          <a href='/' className="brand-logo" >
            <img className="logo-abbr" src="./images/logo.png" alt="" />
            <img className="logo-compact" src="./images/logo-text.png" alt="" />
            <img className="brand-title" src="./images/logo-text.png" alt="" />
          </a>
        </div>
        <div className="header">
          <div className="header-content">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse justify-content-between">
                <div className="header-left">
                </div>
                <ul className="navbar-nav header-right">
                  <li className="nav-item dropdown header-profile">
                    <div className="header-info">
                      <span className="text-black"><strong>Lloyd Elsmore Park Badminton Hall</strong></span>
                      <p className="fs-12 mb-0">Test use only</p>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="content-body" style={{ marginLeft: 0, minHeight: '90vh' }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    {startDate.toISOString().slice(0, 10)} ({days[startDate.getDay()]})
                    <h4 className="card-title">
                      <DatePicker className='form-control' selected={startDate} onChange={(date) => (date instanceof Date) && setStartDate(date)} />
                    </h4>

                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="text-center text-dark">
                              Time
                            </th>
                            <th className="text-center text-dark">
                              1
                            </th>
                            <th className="text-center text-dark">
                              2
                            </th>
                            <th className="text-center text-dark">
                              3
                            </th>
                            <th className="text-center text-dark">
                              4
                            </th>
                            <th className="text-center text-dark">
                              5
                            </th>
                            <th className="text-center text-dark">
                              6
                            </th>
                            <th className="text-center text-dark">
                              7
                            </th>
                            <th className="text-center text-dark">
                              8
                            </th>
                            <th className="text-center text-dark">
                              9
                            </th>
                            <th className="text-center text-dark">
                              10
                            </th>
                            <th className="text-center text-dark">
                              11
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {output.map(element => (
                            <tr>
                              <th className="text-nowrap text-center text-dark" scope="row">{element.time}</th>
                              {
                                Object.entries(element.cell_data).map(([key, value]) => {
                                  const booked = value.text || value.title;
                                  const name = members.find(m => m.memberNumber === value.member_no)?.memberName;
                                  const displayName = value.text || value.title !== 'Booked' ? value.title : name;
                                  return booked
                                    ? (
                                      <td key={key} className="text-center">{displayName}</td>
                                    )
                                    : (
                                      <td key={key} className="text-center"></td>
                                    )
                                })
                              }
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" style={{ paddingLeft: 0 }}>
          <div className="copyright">
            <p>Copyright © Designed &amp; Developed by XXX Studio</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
